import { Injectable } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { Observable, Subject, of } from 'rxjs';
import { SignalREvents } from '../../enums/signalR-events.enum';
import { SignalRResponse } from '../../models/signalR-response.model';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  // private connection: HubConnection;
  private dataSubject: Subject<any> = new Subject<any>();

  constructor() {}

  startConnection(url: string) {
    // if (!this.connection) {
    //   this.connection = new HubConnectionBuilder()
    //     .withUrl(url)
    //     .build();

    //   this.connection.start()
    //     .then(() => console.log('Connection started'))
    //     .catch((err: any) => console.log(`Error while starting connection: ${err}`));
    // }
  }

  public listenOnEvent(event: SignalREvents): Observable<SignalRResponse> {
    // this.connection.on(event, (data) => {
    //   this.dataSubject.next(data);
    // });
    // return this.dataSubject.asObservable();
    return of(null);
  }

  sendDataOnEvent(data: any, event: SignalREvents) {
    // if (this.connection) {
    //   this.connection.invoke(event, data)
    //     .catch((err: any) => console.log(`Error while sending data: ${err}`));
    // }
  }
}
