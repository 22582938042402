import { Injectable } from '@angular/core';
import {
  Observable, catchError, map, of, retry, tap,
} from 'rxjs';
import { HttpCacheService, HttpClientService } from '@amaris/lib-highway/services';
import { HttpOptionsModel, TableSort } from '@amaris/lib-highway/models';
/* fake data import */
import { ToastrService } from 'ngx-toastr';
/* fake data import */
import { Patient } from '../../../models/patient.model';
import { PatientExtended } from '../../../models/patient-extended.model';
import { ApiResponse } from '../../../models/api-response.model';
import { SignalRService } from '../../signalR/signal-r.service';
import { environment } from '../../../../environments/environment';
import { ApiEndpoints } from '../../../enums/api-endpoints.enum';
import { DataStoreService } from '../../data-store/data-store.service';
import { MedicalHistoryTab } from '../../../models/medical-history-tab.model';
import { Dropdown } from '../../../models/dropdown.model';
import { PatientMinimal } from '../../../models/patient-minimal.model';

@Injectable({
  providedIn: 'root',
})
export class PatientsApiService {
  constructor(
    private readonly signalRService: SignalRService,
    private readonly httpClientService: HttpClientService,
    private readonly httpCacheService: HttpCacheService,
    private readonly toastrService: ToastrService,
    private readonly dataStoreService: DataStoreService,
  ) {}

  // Real Api Calls

  // POST
  createPatient(patient: Patient): Observable<string> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Patients,
      body: patient,
      withCredentials: true,
    };

    return this.httpClientService.post<ApiResponse<string>>(options).pipe(
      map((response) => response.data),
      catchError(() => {
        this.toastrService.error('An error occured while creating the patient. Please try again later.');
        return of(null);
      }),
    );
  }

  // PUT
  updatePatient(id: string, patient: Patient): Observable<string> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${id}`,
      body: patient,
      withCredentials: true,
    };
    // this.httpCacheService.remove(`get-patient-detail-${id}`);

    return this.httpClientService.put<ApiResponse<string>>(options).pipe(
      map((response) => response.data),
      tap((userId) => {
        this.toastrService.success('Patient mis à jour avec succès.');
        this.getPatientById(userId).subscribe();
      }),
      catchError(() => {
        this.toastrService.error('Une erreur est survenue lors de la mise à jour du patient. Veuillez réessayer plus tard.');
        return of(null);
      }),
    );
  }

  // GET
  getPatientsFiltered(query: string): Observable<Patient[]> {
    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Patients,
      params: { query },
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<Patient[]>>(options).pipe(
      map((response) => response.data),
      tap((patients) => {
        this.dataStoreService.setPatients(patients);
      }),
    );
  }

  getMinimalPatientsFiltered(patientId?: string, query?: string): Observable<PatientMinimal[]> {
    const params: any = {};
    if (patientId) {
      params.patientId = patientId;
      const minimalPatient = this.dataStoreService.getMinialPatientById(patientId);
      if (minimalPatient) {
        return of([minimalPatient]);
      }
    }

    if (query) {
      params.query = query;
    }

    const options: HttpOptionsModel = {
      url: environment.apiUrl + ApiEndpoints.Patients + ApiEndpoints.Minimal,
      params,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<PatientMinimal[]>>(options).pipe(
      map((response) => response.data),
      tap((patients) => {
        this.dataStoreService.setMinimalPatients(patients);
      }),
    );
  }

  getPropertiesPatientById(id: string): Observable<PatientExtended> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${id}`,
      withCredentials: true,
      // cacheSeconds: 2 * 60,
      // key: `get-patient-detail-${id}`,
    };
    return this.httpClientService.get<ApiResponse<Patient>>(options).pipe(
      map((response) => {
        if (!response?.success) {
          return null;
        }
        return response.data as PatientExtended;
      }),
      tap((patient) => {
        if (patient) {
          patient.birthDate = new Date(patient.birthDate);
          patient.consultationLatestVisitDate = new Date(patient.consultationLatestVisitDate);
          patient.antecedentsTypes.forEach((type: MedicalHistoryTab) => {
            type.title = this.dataStoreService.antecedentsTypes$.value.find((item: Dropdown) => item.value === type.id).label;
          });
        }
      }),
      retry(3),
    );
  }

  getPatientById(id: string): Observable<PatientExtended> {
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}/${id}`,
      withCredentials: true,
      // cacheSeconds: 2 * 60,
      // key: `get-patient-detail-${id}`,
    };
    return this.httpClientService.get<ApiResponse<Patient>>(options).pipe(
      map((response) => {
        if (!response?.success) {
          return null;
        }
        return response.data as PatientExtended;
      }),
      tap((patient) => {
        if (patient) {
          patient.birthDate = new Date(patient.birthDate);
          patient.consultationLatestVisitDate = new Date(patient.consultationLatestVisitDate);
          patient.antecedentsTypes.forEach((type: MedicalHistoryTab) => {
            type.title = this.dataStoreService.antecedentsTypes$.value.find((item: Dropdown) => item.value === type.id).label;
          });
          this.dataStoreService.setCurrentPatientExtended(patient);
        }
      }),
      retry(3),
    );
  }

  getPaginatedPatientsFilteredSorted(
    itemsPerPage: number,
    requestedPage: number,
    sort: TableSort = null,
    filter: string = '',
  ): Observable<ApiResponse<Patient[]>> {
    const params: any = {
      'page[limit]': itemsPerPage,
      'page[current]': requestedPage,
    };
    if (sort && sort.sortOrder) {
      params['sort'] = (sort.sortOrder === 'desc' ? '-' : '') + sort.sortBy;
    }
    if (filter) {
      params['query'] = filter;
    }
    const options: HttpOptionsModel = {
      url: `${environment.apiUrl + ApiEndpoints.Patients}`,
      params,
      withCredentials: true,
    };

    return this.httpClientService.get<ApiResponse<Patient[]>>(options).pipe(
      tap((response) => {
        response.data.forEach((patient: Patient) => {
          patient.birthDate = new Date(patient.birthDate);
          if (patient.consultationLatestVisitDate) {
            patient.consultationLatestVisitDate = new Date(patient.consultationLatestVisitDate);
          }
        });
      }),
    );
  }
}
